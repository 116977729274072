import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/no-image.png'


const _hoisted_1 = { class: "app-data-table-item-img" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_table_header = _resolveComponent("data-table-header")!
  const _component_data_table = _resolveComponent("data-table", true)!

  return (_openBlock(), _createBlock(_component_data_table, {
    headers: _ctx.headers,
    items: _ctx.items,
    isAction: true,
    onRowClicked: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('row-clicked', $event))),
    loading: _ctx.loading,
    pagination: _ctx.list,
    onPageChanged: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit("page-changed", $event)))
  }, {
    headers: _withCtx(({ headers }) => [
      _createVNode(_component_data_table_header, {
        headers: headers,
        onUpdateAutocomplete: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit("update-autocomplete", $event))),
        sorting: _ctx.sorting,
        onSortingChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit("sorting-changed", $event)))
      }, null, 8, ["headers", "sorting"])
    ]),
    [`item.personal_avatar`]: _withCtx(({ item }) => [
      _createElementVNode("div", _hoisted_1, [
        ((item.personal_avatar ?? "").indexOf("base64") > 0)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: item.personal_avatar
            }, null, 8, _hoisted_2))
          : (_openBlock(), _createElementBlock("img", _hoisted_3))
      ])
    ]),
    _: 2
  }, 1032, ["headers", "items", "loading", "pagination"]))
}