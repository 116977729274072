
import {defineComponent, computed, PropType, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import DataTable from '@/components/tables/DataTable.vue';
import DataTableHeader from '@/components/tables/DataTableHeader.vue';
import {Resources, Tags, TypeResources} from "@/models";
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {ucFirst} from '@/helpers/firstRegister';

export default defineComponent({
	emits: ["row-clicked", "page-changed", "item-removed", "list-updated", "item-copied", "update-autocomplete", "sorting-changed"],
	components: {
		DataTable,
		DataTableHeader
	},
	props: {
		list: {
			type: Object as PropType<Resources.ResourceList>,
			default: null
		},
		loading: {
			type: Boolean,
			default: false
		},
		sorting: {
			type: Object
		}
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const store = useStore();
		const itemActiveId = ref(null as number|null);

		const headers = computed(() => [
			{ classCell: 'pr-0', value: 'personal_avatar', width: "1%" },
			{ text: t("dictionary.name"), value: 'name', sortCriterion: 'name' },
			{ text: t("dictionary.tag"), value: 'tag_name' },
			{ text: t("dictionary.resource"), value: 'resource_name', filters: { autocomplete: true, options: itemsResources.value } },
			{ text: t("dictionary.status"), value: 'date_time_end', status: {itemActiveId: itemActiveId.value, action: (item: Resources.Resource) => item.date_time_end ? unblock(item.id) : block(item.id)}, width: "1%" },
			{ classCell: 'px-0', icon: {name: 'copy', title: `${t("button.copy")} ${t("dictionary.resource")}`, action: (item: Resources.Resource) => emit("item-copied", item)}, width: "1%" },
			{ classCell: 'pl-0', icon: {name: 'basket', title: `${t("button.remove")} ${t("dictionary.resource")}`, action: (item: Resources.Resource) => emit("item-removed", item)}, width: "1%" },
		])

		const tags = computed(() => store.state.dictionaries.tags as Tags.Tag[]);
		const typeResources = computed(() => store.state.dictionaries.typeResources as TypeResources.TypeResource[]);

		const items = computed(() => props.list.items.map(item => {
			const findTag = (id: number) => tags.value.find((tag: Tags.Tag) => tag.id === id);
			const findResource = (id: number) => typeResources.value.find((typeResource: TypeResources.TypeResource) => typeResource.id === id);

			return {
				...item,
				tag_name: findTag(item.id_tag)?.comment,
				resource_name: findResource(item.id_type_res)?.name
			}
		}));

		const itemsResources = computed(() => {
			return [
				{id: null, name: `${t("dictionary.all")} ${t("dictionary.typeResources")}`},
				...typeResources.value
			]
		})

		const service = injectOrThrow<Resources.ResourceService>('Resources.ResourceService');

		const block = async (id: number) => {
			itemActiveId.value = id;

			try {
				await service.block(id);

				store.commit('notification/successMessage', {title: t("form.notify.success.blocked", { name: ucFirst(t("dictionary.resource")) })});
				emit("list-updated");
			} catch (e) {
				return;
			} finally {
				itemActiveId.value = null;
			}
		};

		const unblock = async (id: number) => {
			itemActiveId.value = id;

			try {
				await service.unblock(id);

				store.commit('notification/successMessage', {title: t("form.notify.success.unblocked", { name: ucFirst(t("dictionary.resource")) })});
				emit("list-updated");
			} catch (e) {
				return;
			} finally {
				itemActiveId.value = null;
			}
		};

		return { headers, items, itemsResources }
	}
})
