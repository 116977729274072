
import {defineComponent, ref, onMounted, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import {injectOrThrow} from "@/helpers/inject";
import {Searches} from "@/models";
import {useForm, useField} from 'vee-validate';
import {useRouter, useRoute} from "vue-router";

export default defineComponent({
	props: {
		tableName: {
			type: String,
			default: null
		}
	},
	setup(props) {
		const { t } = useI18n();
		const {handleSubmit, setValues, handleReset} = useForm();
		const route = useRoute();
		const router = useRouter();

		const service = injectOrThrow<Searches.SearchService>('Searches.SearchService');

		const listColumns = ref([] as Searches.ColumnName[])

		onMounted(async () => {
			if(props.tableName) {
				listColumns.value = await service.listFields(props.tableName);

				const isSelectColumnName = listColumns.value.find(column => column.id === route.query.field) || listColumns.value.find(column => column.id === 'name') || listColumns.value.find(column => column.id === 'id')

				if(isSelectColumnName)
					selectColumnName(isSelectColumnName);
				if(typeof route.query.value === 'string')
					value.value = route.query.value;
			}
		});

		const { value: selectedColumnName, errorMessage: columnNameError } = useField<Searches.ColumnName>('selectedColumnName', 'required');
		function selectColumnName(selected: Searches.ColumnName) {
			selectedColumnName.value = selected ?? {};
		}

		const { value: value, errorMessage: valueError } = useField<string>('value', 'required');

		const search = handleSubmit(() => {
			return router.replace({ query: {
				table: props.tableName,
				field: selectedColumnName.value.column_name,
				value: value.value,
				data_type: selectedColumnName.value.data_type
			} });
		});

		const clear = () => {
			router.replace({ query: {} });

			setValues({ value: "" });

			handleReset();

			const isSelectColumnName = listColumns.value.find(column => column.id === 'name') || listColumns.value.find(column => column.id === 'id')

			if(isSelectColumnName)
				selectColumnName(isSelectColumnName);
		};

		watch(() => route.query, () => {
			if(Object.keys(route.query).length === 0)
				clear();
		})

		return {
			t, route,
			listColumns, selectedColumnName, selectColumnName, columnNameError,
			value, valueError,
			search, clear
		}

	}
})
