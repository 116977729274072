
import {defineComponent, ref, onMounted, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {List} from "@/components/tables/DataTable";
import DataTable from "./components/listing/DataTable.vue";
import {injectOrThrow} from "@/helpers/inject";
import {Resources, TypeResources, Searches} from "@/models";
import {useRouter, useRoute} from "vue-router";
import Add from "./Add.vue";
import Edit from "./Edit.vue";
import Remove from "@/views/components/dialogs/Remove.vue";
import {setCookie} from '@/helpers/cookie';
import Search from "@/views/components/Search.vue";

export default defineComponent({
	components: {
		DataTable,
		Add,
		Edit,
		Remove,
		Search
	},
	setup() {
		const { t } = useI18n();
		const route = useRoute();
		const router = useRouter();
		const { state, commit, dispatch } = useStore();

		const changePage = (pagination: List) => {
			load({
				pageNumber: pagination.current,
				pageSize: pagination.size
			})

			commit('globals/setPageSize', pagination.size);
			setCookie("pageSize", pagination.size);
		};

		const list = ref(route.name === 'searches'
			? new Searches.SearchList({ size: state.globals.pageSize })
			: new Resources.ResourceList({ size: state.globals.pageSize })
		);
		const query = ref<Resources.ResourceQueryParams>({ pageSize: state.globals.pageSize });
		const service = injectOrThrow<Resources.ResourceService>('Resources.ResourceService');
		const searchService = injectOrThrow<Searches.SearchService>('Searches.SearchService');

		const id_type_res = ref();

		const loading = ref(true);
		const load = async (params: Resources.ResourceQueryParams) => {
			if(state.globals.enterpriseId) {
				loading.value = true;
				query.value = { ...query.value, ...params, ...route.query };

				if(route.query.table) {
					list.value = await searchService.search({...query.value, id_type_res: id_type_res.value})
				} else {
					list.value = id_type_res.value
						? await service.listByType(query.value, state.globals.enterpriseId, id_type_res.value)
						: await service.list(query.value, state.globals.enterpriseId);
				}

				loading.value = false;
			} else {
				router.push({name: "change_enterprise"})
			}
		}

		onMounted(async () => {
			await dispatch('dictionaries/getTypeResources');
			load(query.value)
		});

		watch(() => route.query, () => load(query.value));

		const typeResource = ref({} as TypeResources.TypeResource);
		const updateAutocomplete = (value: TypeResources.TypeResource) => {
			id_type_res.value = value.id
			load(query.value);
			typeResource.value = value;
		}

		const isAddOpened = ref(false);

		const item = ref({} as Resources.Resource);

		const isEditOpened = ref(false);
		const edit = (value: Resources.Resource) => {
			item.value = value;
			isEditOpened.value = true;
		};

		const isRemoveOpened = ref(false);
		const remove = (value: Resources.Resource) => {
			item.value = value;
			isRemoveOpened.value = true;
		};

		const copy = (value: Resources.Resource) => {
			item.value = value;
			isAddOpened.value = true;
		}

		const sort = async (value: Resources.ResourceQueryParams) => {
			const isCriterionSame = query.value.sortCriterion === value.sortCriterion;

			await load({
				pageNumber: 1,
				sortCriterion: value.sortCriterion,
				sortDirection: query.value.sortDirection === 0 && isCriterionSame ? 1 : 0,
			});
		}

		return {
			t, service, route,
			loading, load, changePage,
			list, item,
			typeResource, updateAutocomplete,
			isAddOpened, copy,
			isEditOpened, edit,
			isRemoveOpened, remove,
			query, sort
		}
	}
})
